import {graphql, useStaticQuery} from "gatsby"
const useImageThumbnail = () => {
  return useStaticQuery(graphql`
      query MyImages {

          fences_custom_bark_on_cedar_pool_fence_100 : file(relativePath: { eq: "fences/custom-bark-on-cedar-pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_picture_frame_pool_fence_with_welded_wire_infill_100 : file(relativePath: { eq: "fences/cedar-picture-frame-pool-fence-with-welded-wire-infill_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_custom_bark_on_cedar_fence_and_gates_100 : file(relativePath: { eq: "fences/custom-bark-on-cedar-fence-and-gates_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_1_100 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-1_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_2_100 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_3_100 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-3_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_4_100 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-4_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_deer_fencing_100 : file(relativePath: { eq: "fences/deer-fencing_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_4h_3_rail_round_cedar_100 : file(relativePath: { eq: "fences/4h-3-rail-round-cedar_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_montauk_yacht_club_glass_pool_fence_100 : file(relativePath: { eq: "fences/montauk-yacht-club-glass-pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_100 : file(relativePath: { eq: "fences/glass-pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_in_the_hamptons_100 : file(relativePath: { eq: "fences/glass-pool-fence-in-the-hamptons_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_3_rail_split_100 : file(relativePath: { eq: "fences/3-rail-split_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_vinyl_picket_pool_fence_100 : file(relativePath: { eq: "fences/vinyl-picket-pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_custom_pergola_100 : file(relativePath: { eq: "fences/custom-pergola_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_pool_fence_100 : file(relativePath: { eq: "fences/pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_4_foot_picket_set_on_8_inch_posts_sleeved_over_existing_bollards_100 : file(relativePath: { eq: "fences/4-foot-picket-set-on-8-inch-posts-sleeved-over-existing-bollards_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_pool_equipment_enclosure_100 : file(relativePath: { eq: "fences/pool-equipment-enclosure_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_two_tone_clay_and_white_with_scalloped_picket_top_100 : file(relativePath: { eq: "fences/two-tone-clay-and-white-with-scalloped-picket-top_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_with_lattice_gate_100 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-with-lattice-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_two_rail_100 : file(relativePath: { eq: "fences/two-rail_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_bark_on_cedar_garden_100 : file(relativePath: { eq: "fences/bark-on-cedar-garden_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_victorian_pool_fence_100 : file(relativePath: { eq: "fences/cedar-victorian-pool-fence_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_2_100 : file(relativePath: { eq: "fences/glass-pool-fence-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_wood_and_woven_wire_100 : file(relativePath: { eq: "fences/wood-and-woven-wire_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_vinyl_privacy_100 : file(relativePath: { eq: "fences/vinyl-privacy_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_stepped_100 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-stepped_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_classic_victorian_picket_and_privacy_100 : file(relativePath: { eq: "fences/cedar-classic-victorian-picket-and-privacy_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_6_foot_white_vinyl_tapered_to_4_foot_with_4_foot_white_vinyl_picket_100 : file(relativePath: { eq: "fences/6-foot-white-vinyl-tapered-to-4-foot-with-4-foot-white-vinyl-picket_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_privacy_1_100 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-privacy-1_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_privacy_2_100 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-privacy-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_galvanized_chain_link_at_a_solar_farm_100 : file(relativePath: { eq: "fences/galvanized-chain-link-at-a-solar-farm_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_3_100 : file(relativePath: { eq: "fences/glass-pool-fence-3_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_post_and_board_pool_fence_with_wire_mesh_100 : file(relativePath: { eq: "fences/post-and-board-pool-fence-with-wire-mesh_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_three_rail_100 : file(relativePath: { eq: "fences/three-rail_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_white_aluminum_100 : file(relativePath: { eq: "fences/white-aluminum_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_woven_wire_with_pressure_treated_posts_100 : file(relativePath: { eq: "fences/woven-wire-with-pressure-treated-posts_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_automated_farmhouse_gate_1_100 : file(relativePath: { eq: "gates/automated-farmhouse-gate-1_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_automated_farmhouse_gate_2_100 : file(relativePath: { eq: "gates/automated-farmhouse-gate-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_criss_cross_picket_automatic_gate_100 : file(relativePath: { eq: "gates/criss-cross-picket-automatic-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_cedar_gate_concealed_stainless_steel_frame_100 : file(relativePath: { eq: "gates/custom-cedar-gate-concealed-stainless-steel-frame_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_double_steel_gates_with_cedar_infill_100 : file(relativePath: { eq: "gates/custom-double-steel-gates-with-cedar-infill_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_gate_with_bear_100 : file(relativePath: { eq: "gates/custom-gate-with-bear_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          

          gates_custom_powder_coated_steel_gate_with_cedar_infill_100 : file(relativePath: { eq: "gates/custom-powder-coated-steel-gate-with-cedar-infill_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_double_drive_slide_gate_100 : file(relativePath: { eq: "gates/double-drive-slide-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_farm_style_gate_100 : file(relativePath: { eq: "gates/farm-style-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_gate_closed_100 : file(relativePath: { eq: "gates/gate-closed_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_iron_driveway_gate_100 : file(relativePath: { eq: "gates/iron-driveway-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_red_cedar_three_rail_with_concealed_stainless_steel_frame_100 : file(relativePath: { eq: "gates/red-cedar-three-rail-with-concealed-stainless-steel-frame_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_steel_gate_custom_cedar_infill_100 : file(relativePath: { eq: "gates/steel-gate-custom-cedar-infill_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_white_cedar_gate_with_concealed_stainless_steel_frame_100 : file(relativePath: { eq: "gates/white-cedar-gate-with-concealed-stainless-steel-frame_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_cedar_gate_with_steel_frame_100 : file(relativePath: { eq: "gates/custom-cedar-gate-with-steel-frame_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wood_farmhouse_style_gate_100 : file(relativePath: { eq: "gates/wood-farmhouse-style-gate_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_gate_with_large_round_pillars_100 : file(relativePath: { eq: "gates/gate-with-large-round-pillars_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wooden_gate_1_100 : file(relativePath: { eq: "gates/wooden-gate-1_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wooden_gate_2_100 : file(relativePath: { eq: "gates/wooden-gate-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_custom_azek_deck_100 : file(relativePath: { eq: "decks/custom-azek-deck_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_custom_trex_deck_100 : file(relativePath: { eq: "decks/custom-trex-deck_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_deck_100 : file(relativePath: { eq: "decks/deck_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_black_aluminum_entry_handrail_100 : file(relativePath: { eq: "railings/black-aluminum-entry-handrail_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_1_100 : file(relativePath: { eq: "railings/cable-railing-1_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_2_100 : file(relativePath: { eq: "railings/cable-railing-2_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_3_100 : file(relativePath: { eq: "railings/cable-railing-3_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_4_100 : file(relativePath: { eq: "railings/cable-railing-4_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_5_100 : file(relativePath: { eq: "railings/cable-railing-5_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_6_100 : file(relativePath: { eq: "railings/cable-railing-6_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_7_100 : file(relativePath: { eq: "railings/cable-railing-7_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_8_100 : file(relativePath: { eq: "railings/cable-railing-8_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_9_100 : file(relativePath: { eq: "railings/cable-railing-9_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          

          railings_white_aluminum_handrail_100 : file(relativePath: { eq: "railings/white-aluminum-handrail_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_custom_fabricated_6_foot_wide_steel_steps_with_bluestone_treads_100 : file(relativePath: { eq: "railings/custom-fabricated-6-foot-wide-steel-steps-with-bluestone-treads_100.jpg"}) {
              childImageSharp {
                  fixed(width:100,height:100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_custom_bark_on_cedar_pool_fence_2000 : file(relativePath: { eq: "fences/custom-bark-on-cedar-pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_picture_frame_pool_fence_with_welded_wire_infill_2000 : file(relativePath: { eq: "fences/cedar-picture-frame-pool-fence-with-welded-wire-infill_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_custom_bark_on_cedar_fence_and_gates_2000 : file(relativePath: { eq: "fences/custom-bark-on-cedar-fence-and-gates_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_1_2000 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-1_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_2_2000 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_3_2000 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-3_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_classic_victorian_on_stonewall_4_2000 : file(relativePath: { eq: "fences/classic-victorian-on-stonewall-4_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_deer_fencing_2000 : file(relativePath: { eq: "fences/deer-fencing_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_4h_3_rail_round_cedar_2000 : file(relativePath: { eq: "fences/4h-3-rail-round-cedar_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_montauk_yacht_club_glass_pool_fence_2000 : file(relativePath: { eq: "fences/montauk-yacht-club-glass-pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_2000 : file(relativePath: { eq: "fences/glass-pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_in_the_hamptons_2000 : file(relativePath: { eq: "fences/glass-pool-fence-in-the-hamptons_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_3_rail_split_2000 : file(relativePath: { eq: "fences/3-rail-split_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_vinyl_picket_pool_fence_2000 : file(relativePath: { eq: "fences/vinyl-picket-pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_custom_pergola_2000 : file(relativePath: { eq: "fences/custom-pergola_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_pool_fence_2000 : file(relativePath: { eq: "fences/pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_4_foot_picket_set_on_8_inch_posts_sleeved_over_existing_bollards_2000 : file(relativePath: { eq: "fences/4-foot-picket-set-on-8-inch-posts-sleeved-over-existing-bollards_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_pool_equipment_enclosure_2000 : file(relativePath: { eq: "fences/pool-equipment-enclosure_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_two_tone_clay_and_white_with_scalloped_picket_top_2000 : file(relativePath: { eq: "fences/two-tone-clay-and-white-with-scalloped-picket-top_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_with_lattice_gate_2000 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-with-lattice-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_two_rail_2000 : file(relativePath: { eq: "fences/two-rail_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_bark_on_cedar_garden_2000 : file(relativePath: { eq: "fences/bark-on-cedar-garden_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_victorian_pool_fence_2000 : file(relativePath: { eq: "fences/cedar-victorian-pool-fence_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_2_2000 : file(relativePath: { eq: "fences/glass-pool-fence-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_wood_and_woven_wire_2000 : file(relativePath: { eq: "fences/wood-and-woven-wire_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_vinyl_privacy_2000 : file(relativePath: { eq: "fences/vinyl-privacy_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_stepped_2000 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-stepped_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_classic_victorian_picket_and_privacy_2000 : file(relativePath: { eq: "fences/cedar-classic-victorian-picket-and-privacy_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_6_foot_white_vinyl_tapered_to_4_foot_with_4_foot_white_vinyl_picket_2000 : file(relativePath: { eq: "fences/6-foot-white-vinyl-tapered-to-4-foot-with-4-foot-white-vinyl-picket_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_privacy_1_2000 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-privacy-1_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_cedar_tongue_and_groove_privacy_2_2000 : file(relativePath: { eq: "fences/cedar-tongue-and-groove-privacy-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_galvanized_chain_link_at_a_solar_farm_2000 : file(relativePath: { eq: "fences/galvanized-chain-link-at-a-solar-farm_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_glass_pool_fence_3_2000 : file(relativePath: { eq: "fences/glass-pool-fence-3_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_post_and_board_pool_fence_with_wire_mesh_2000 : file(relativePath: { eq: "fences/post-and-board-pool-fence-with-wire-mesh_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_three_rail_2000 : file(relativePath: { eq: "fences/three-rail_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_white_aluminum_2000 : file(relativePath: { eq: "fences/white-aluminum_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          fences_woven_wire_with_pressure_treated_posts_2000 : file(relativePath: { eq: "fences/woven-wire-with-pressure-treated-posts_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_automated_farmhouse_gate_1_2000 : file(relativePath: { eq: "gates/automated-farmhouse-gate-1_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_automated_farmhouse_gate_2_2000 : file(relativePath: { eq: "gates/automated-farmhouse-gate-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_criss_cross_picket_automatic_gate_2000 : file(relativePath: { eq: "gates/criss-cross-picket-automatic-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_cedar_gate_concealed_stainless_steel_frame_2000 : file(relativePath: { eq: "gates/custom-cedar-gate-concealed-stainless-steel-frame_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_double_steel_gates_with_cedar_infill_2000 : file(relativePath: { eq: "gates/custom-double-steel-gates-with-cedar-infill_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_gate_with_bear_2000 : file(relativePath: { eq: "gates/custom-gate-with-bear_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          


          gates_custom_powder_coated_steel_gate_with_cedar_infill_2000 : file(relativePath: { eq: "gates/custom-powder-coated-steel-gate-with-cedar-infill_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_double_drive_slide_gate_2000 : file(relativePath: { eq: "gates/double-drive-slide-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_farm_style_gate_2000 : file(relativePath: { eq: "gates/farm-style-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_gate_closed_2000 : file(relativePath: { eq: "gates/gate-closed_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_iron_driveway_gate_2000 : file(relativePath: { eq: "gates/iron-driveway-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_red_cedar_three_rail_with_concealed_stainless_steel_frame_2000 : file(relativePath: { eq: "gates/red-cedar-three-rail-with-concealed-stainless-steel-frame_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_steel_gate_custom_cedar_infill_2000 : file(relativePath: { eq: "gates/steel-gate-custom-cedar-infill_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_white_cedar_gate_with_concealed_stainless_steel_frame_2000 : file(relativePath: { eq: "gates/white-cedar-gate-with-concealed-stainless-steel-frame_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_custom_cedar_gate_with_steel_frame_2000 : file(relativePath: { eq: "gates/custom-cedar-gate-with-steel-frame_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wood_farmhouse_style_gate_2000 : file(relativePath: { eq: "gates/wood-farmhouse-style-gate_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_gate_with_large_round_pillars_2000 : file(relativePath: { eq: "gates/gate-with-large-round-pillars_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wooden_gate_1_2000 : file(relativePath: { eq: "gates/wooden-gate-1_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          gates_wooden_gate_2_2000 : file(relativePath: { eq: "gates/wooden-gate-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_custom_azek_deck_2000 : file(relativePath: { eq: "decks/custom-azek-deck_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_custom_trex_deck_2000 : file(relativePath: { eq: "decks/custom-trex-deck_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          decks_deck_2000 : file(relativePath: { eq: "decks/deck_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_black_aluminum_entry_handrail_2000 : file(relativePath: { eq: "railings/black-aluminum-entry-handrail_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_1_2000 : file(relativePath: { eq: "railings/cable-railing-1_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_2_2000 : file(relativePath: { eq: "railings/cable-railing-2_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_3_2000 : file(relativePath: { eq: "railings/cable-railing-3_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_4_2000 : file(relativePath: { eq: "railings/cable-railing-4_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_5_2000 : file(relativePath: { eq: "railings/cable-railing-5_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_6_2000 : file(relativePath: { eq: "railings/cable-railing-6_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_7_2000 : file(relativePath: { eq: "railings/cable-railing-7_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_8_2000 : file(relativePath: { eq: "railings/cable-railing-8_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_cable_railing_9_2000 : file(relativePath: { eq: "railings/cable-railing-9_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_white_aluminum_handrail_2000 : file(relativePath: { eq: "railings/white-aluminum-handrail_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


          railings_custom_fabricated_6_foot_wide_steel_steps_with_bluestone_treads_2000 : file(relativePath: { eq: "railings/custom-fabricated-6-foot-wide-steel-steps-with-bluestone-treads_2000.jpg"}) {
              childImageSharp {
                  fixed(width:2000,height:2000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }


      }
  `);
};

export default useImageThumbnail;