import { useStaticQuery, graphql } from "gatsby"
const useSiteMetadata = () => {
  const {site, allFile} = useStaticQuery(graphql`
      query MyQuery {
          site {
              siteMetadata {
                  title
                  metaTitle
                  description
                  lang
                  locale
                  siteUrl
                  author{
                      name
                  }
                  sections
                  favicon
                  logo
                  menuItems{
                      path
                      label
                  }
                  social{
                      service
                      url
                      faImport
                      fa
                      color
                      hoverColor
                  }
                  footer{
                      text_1
                      text_2
                      text_3
                      logo
                  }
                  address{
                      line1
                      line2
                      line3
                  }
                  contacts {
                      text
                      url
                  }
              }
          }
          allFile(filter: {dir: {regex: "\/images\/"}}) {
              edges {
                  node {
                      id
                      dir
                      rdev
                      name
                  }
              }
          }
      }
  `);
  return {
    site: site.siteMetadata,
    files: allFile.edges.map((item) => item.node)
  };
};

export default useSiteMetadata;