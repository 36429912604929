import React,{useState} from "react";
import content from "../../content/about.yaml";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const headings = content.items.map((item) => item.heading);
let currentAutoSetting = "";
let currentUserClicked = false;

export default () => {

  const [blFlipAutoTimeoutStarted, setFlipAutoTimeoutStarted] = useState(false);
  const [flippedCard, setFlippedCard] = useState("");

  const aboutItemClickCallback = (item) => {
    currentUserClicked = true;
    if (!!item.heading) {
      if (flippedCard === item.heading) {
        setFlippedCard("");
      } else {
        setFlippedCard(item.heading);
      }
    }
  };

  const flipAutoTimeout = () => {
    setTimeout(() => {
      if (!currentUserClicked) {
        const currentIndex = headings.indexOf(currentAutoSetting);
        const nextFlipCardIndex = (currentIndex + 1) % (headings.length + 1);
        const nextFlipCard = headings[nextFlipCardIndex];
        currentAutoSetting = nextFlipCard;
        setFlippedCard(nextFlipCard);
        flipAutoTimeout();
      }
    }, 3000);
  };

  if (!blFlipAutoTimeoutStarted) {
    setFlipAutoTimeoutStarted(true);
    flipAutoTimeout();
  }

  return (
    <section id="about">
      <div>
        <div className="row">
          <div className={"row section-head"}>
            <h1>{content.headline}</h1>
            <p>{content.description}</p>
          </div>
          <div className={"row"}>
            <div className="tables bgrid-thirds s-bgrid-halves">
              {
                !!content.items ? content.items.map((item, index) =>
                  <div
                    className="column"
                    onClick={aboutItemClickCallback.bind(this, item)}
                    key={`about_${index}`}
                  >
                    <div
                      className={`flip-card ${flippedCard === item.heading ? `flip-card-hold-flipped-to-back` : ``}`}>
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <div className="flip-card-block">
                            <p className="flip-card-image">
                              <img
                                src={item.image}
                                alt={`Flip Card ${item.heading} ${index + 1} of ${content.items.length}`}
                              />
                            </p>
                            <h3
                              className="plan-title"
                            >{item.heading}</h3>
                          </div>
                        </div>
                        <div className="flip-card-back">
                          <div className="flip-card-block">
                            <h3
                              className="plan-title"
                            >{item.heading}</h3>
                            <ul className="features">
                              <li>{item.body}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="tables bgrid-thirds s-bgrid-halves">
            {
              !!content.suppliers ? content.suppliers.map((item, index) =>
                <div
                  className="column"
                  key={`supplier_${index}`}
                >
                  <a
                    href={`${item.url}`}
                    target="_blank"
                    rel={`noopener noreferrer nofollow`}
                  >
                    <LazyLoadImage
                      height={150}
                      width={200}
                      delayTime={5000}
                      effect="blur"
                      alt={`Supplier Image Link ${index + 1} of ${content.suppliers.length}`}
                      src={`${item.image}`}
                      wrapperClassName={`supplier-image`}
                    />
                  </a>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    </section>
  )
};