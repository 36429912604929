import React from "react"
import { ParallaxProvider,Parallax } from 'react-scroll-parallax';
import styled from "styled-components/macro";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import content from "../../content/hero.yaml"

const StyledHeadlineContainer =  styled.div`
  @media screen and (max-height: 600px) {
    opacity:0.8;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width:100%;
    background-color: #181d21;
    border:50px;
  }
`;

const Hero = () => {
  return (
    <ParallaxProvider>
      <section id="hero">
        <div className="row" style={{maxWidth: "100%", width: "100%"}}>
          <div className="twelve columns" style={{padding: 0}}>
            <StyledHeadlineContainer className="hero-text">
              <span className="responsive-headline-1 animated fadeInUp">{content.headline_1}</span>
              <span className="responsive-headline-2 animated fadeInUp">{content.headline_2}</span>
              <span className="responsive-headline-3 animated fadeInUp">{content.headline_3}</span>
            </StyledHeadlineContainer>
            <div style={{textAlign: 'center'}}>
              <Parallax className="header-parallax-image animated fadeIn" y={[-50, 30]} tagOuter="figure">
                <div className="hero-image-background animated fadeInUp">
                  <div className="hero-image">
                    <LazyLoadImage
                      height={216}
                      width={191}
                      delayTime={3000}
                      effect="blur"
                      alt={"Connecticut Fence and Gate Logo in Hero Section"}
                      src={content.image}
                    />
                    <span>
                          <h1 className="responsive-brand-headline animated fadeInLeft">{content.name_1}</h1>
                          <h1 className="responsive-brand-headline animated fadeInRight">{content.name_2}</h1>
                                </span>
                  </div>
                </div>
                <div
                  className="buttons"
                >
                  <div className="hero-request-estimate-button">
                    <a
                      href={content.button1.to}
                      className="button trial"
                      target="_blank"
                      rel={`noopener noreferrer nofollow`}
                    >{content.button1.label}</a>
                  </div>
                  <div>
                  </div>
                </div>
              </Parallax>
            </div>
          </div>
        </div>
      </section>
    </ParallaxProvider>
  );
};

export default Hero;