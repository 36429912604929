import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import ImageSection from "../components/ImageSection"
import CallToAction from "../components/call-to-action"
import Testimonials from "../components/testimonials"

import useSiteMetadata from "../hooks/use-site-metadata"

const IndexPage = () => {
  const { sections } = useSiteMetadata().site;
  const availableSections = {
    "hero": Hero,
    "about": About,
    "fences": ImageSection,
    "gates": ImageSection,
    "decks": ImageSection,
    "railings": ImageSection,
    "call-to-action": CallToAction,
    "testimonials": Testimonials,
  };
  return(
    <div>
      <Layout>
        <SEO/>
        {
          sections.map((item,index) => {
            let TagName = availableSections[item];
            return (
              <TagName
                key={index}
                sectionName={item}
              />
            )
          })
        }
      </Layout>
    </div>
  )
};

export default IndexPage;